.ly-sidebar-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    /* box-shadow: var(--shadow-light-right) */
}
.ly-sidebar-top{
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
}
.ly-sidebar-link {
    display: flex;
    border-radius: 25px;
    width: 90%;
    min-height: 50px;
    padding: 8px 20px;
}
.ly-sidebar-link>div {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    border-radius: 5px;
    column-gap: 8px;
    align-items: center;
}
.ly-sidebar-link>div>p{
    color: black;
    align-self: center;
    font-size: 16px;
}
.ly-sidebar-link>div>div{
    align-self: center;
}
.ly-sidebar-link>div svg{
    align-self: center;
}
.ly-sidebar-link.active{
    background-color: var(--color-primary-x-light);
}
.ly-sidebar-link.transitioning{
    background-color: yellow;
}

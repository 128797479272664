.asr-documents-wrapper{
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;
}
.asr-documents-wrapper .document-item{
    border:1px solid #f1f1f1;
    width: 150px;
    background-color: #f1f1f1;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
}

.asr-documents-wrapper .document-item:hover{
    border:1px solid lightgray;
}

.asr-documents-wrapper .document-icon{
    width: 150px;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.asr-documents-wrapper .document-title{
    text-align: center;
}

.document-checkbox {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
.label-description{
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: space-between;
}

.label-description-item{
    display: flex;
    width: 100%;
}

.label-description.column-2 > .label-description-item{
width: 50%;
}


.label-description-item > .item-label{
    width: 30%;
    color: rgb(44, 43, 43);
    font-style: italic;
    font-size: .95em;
}

.label-description-item > .item-descriptoin{
    width: 60%;
    flex-grow: 1;
    text-align: right;
}

.text-link-span{
    color: #0881cc;
    cursor: pointer;
    font-weight: 500;
}
.text-link-span.light-gray{
    color: gray;
    font-weight: 400;
}


.text-link-span.mini-text{
    font-size: .9em;
}


.grid{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    flex-direction: row;
}

.grid>div{
    flex-grow: 1;
    width: 100%;
}

.grid.grid-column-2>div{
    width: 40%;
}


.grid.grid-column-3>div{
    width: 30%;
}

.grid.grid-column-4>div{
    width: 22%;
}
.list-search-container{
    display: flex;
    flex-direction: row;
    align-items: center;
   
}

.asr-pagi{
    /* background-color: pink; */
    display: flex;
    justify-content:space-between;
    padding: 10px 1px;
}
.asr-pagi-left{

}

.asr-pagi-right{
    display: flex;
    align-items: center;
}
.asr-pagi-page-selector{
    border:1px solid lightgray;
    padding: 5px;
    border-radius: 5px;
}
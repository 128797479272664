
.setting-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
}
.setting-item{
    width: 100%;
    max-width: 400px;
    border-bottom: 1px solid #ece6e6;
    cursor: pointer;
    background-color: white;
    border-radius: 5px;
    box-shadow: var(--shadow-light);
    padding: 10px;
    min-height: 100px;
    display: flex;
}

.setting-item .setting-item-icon{
    width: 80px;
    font-size: 1.05em;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.setting-item .setting-item-title{
    font-weight: 500;
    font-size: 1.05em;
    margin-bottom: 8px;
}

.setting-item-desc{
    line-height: 1.2em;
    color: gray;
    font-size: .9em;
    font-style: italic;
}
.setting-item:hover{
    box-shadow: var(--shadow-dark);
}

.setting-item:last-child{
    border-bottom: none;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a{
  text-decoration: none;
}
:root {
  --color-primary: #118eed;
  --color-primary-light: #92c7f0;
  --color-primary-x-light: #dbf0ff;
  --color-primary-bg: #f1f9ff;
  --color-gray: #49545c;
  --color-gray-light: lightgray;
  --shadow-light-bottom: 0px 2px 8px lightgrey;
  --shadow-light-right: 2px 0 8px lightgrey;
  --shadow-light: 0 0 10px -5px grey;
  --shadow-dark: 0 0 12px -5px rgb(78, 77, 77);
  --border-gray-light:1px solid rgb(174, 175, 177);
  --color-a:#118eed;
  --color-a-light:#e0f2ff;
  --color-b:#00762e;
  --color-b-light:#c0f5d4;
  --color-c:red;
  --color-c-light:#ffd8df
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-primary-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  /* color:var(--color-primary); */
}
ul {
  list-style: none;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background:lightgray
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 10px;
}
.image{
  max-width: 100%;
  max-height: 100%;
}

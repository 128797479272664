.asr-button-theme-primary {
  background-color: var(--color-primary);
}

.asr-modal{
  width: 100%;
  max-width: 800px;
}


.asr-card-header-title--subhead{
  color:'gray';
}
.asr-dropdown-button-toggle{
  min-width: 120px;
}
.prof-sett-top{
    display: flex;
    padding: 20px;
}
.logo-wrapper{
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
}
.right-form{
    flex-grow: 1;
}
.prof-sett-top .image-cropper{
    background-color: var(--color-gray-light);
    width: 250px;
    height: 250px;
}
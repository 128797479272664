
.ly-panel-wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    min-width: 1000px;
}
.ly-panel-header{
    height: 50px;
    /* max-height: 100px; */
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

}

.ly-panel-body{
    display: flex;
    align-items: stretch;
    flex-grow: 1;
    overflow: auto;
}
.ly-panel-side-bar{
    min-width: 250px;
    border-radius: 10px;
    margin:25px auto 0 auto;
}
.ly-panel-content{
   
    flex-grow: 1;
    padding: 10px;
    overflow: auto;
    /* position: fixed;
    top: 100px;
    bottom: 0;
    left: 100px;
    right: 0; */
}
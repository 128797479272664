.ly-header{
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: var(--shadow-light-bottom);
    background-color: var(--color-primary-bg);
}
.ly-header .logo-wrapper{
    max-height: 50px;
    max-width: 80px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ly-header .company-title{
    color:black;
    font-weight: bolder;
}

.ly-header-left{
    color: white;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    justify-content: center;
}
.ly-header-right{
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.ly-header-right-icons{
    display: flex;
    column-gap: 25px;
    margin-right: 25px;
}
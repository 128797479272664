.tabs{
    display: flex;
    margin: 10px auto;
}
.tabs li{
    padding: 5px 20px;
    font-weight: 600;
    border-right: var(--border-gray-light);
    cursor: pointer;
    text-align: center;
}

.tabs li:last-child{
    border-right: none;
}
.tabs li.tabs-back{
    /* border-right: none; */
}